import {fetchAuthSession} from 'aws-amplify/auth';

import {ROUTE_URLS} from '../../constants/routes';

const FSSO_RETRY_ATTEMPTS_KEY = 'FSSO_RETRY_ATTEMPTS_KEY';
const FSSO_RETRY_ATTEMPTS_CODE = 'RETRYING';
export const CSRF_STORAGE_KEY = 'x-horizon-csrf-token'

function getFssoRetryBlock() {
  const FSSORetries = window.sessionStorage.getItem(FSSO_RETRY_ATTEMPTS_KEY);
  return FSSORetries === FSSO_RETRY_ATTEMPTS_CODE
}

function setFssoRetryBlock(setBlocked) {
  if (setBlocked) {
    window.sessionStorage.setItem(FSSO_RETRY_ATTEMPTS_KEY, FSSO_RETRY_ATTEMPTS_CODE);
  }
  else {
    window.sessionStorage.removeItem(FSSO_RETRY_ATTEMPTS_KEY);
  }
}

/**
 * Get Auth currentSession access token's JWT token. if fails then triggers
 * Auth.federatedSignIn with customState value of current location in coded format
 *
 * @returns {Promise<string>}
 */
function getToken() {
  return fetchAuthSession()
    .then(({tokens}) => {
      const {accessToken} = tokens ?? {};
      return accessToken;
    })
    .catch(() => {
      if (!getFssoRetryBlock()) {
        const encodedUrl = encodeUrl(window.location.href);
        window.location.assign(`${window.location.origin}${ROUTE_URLS.LOGIN}?redirectUrl=${encodedUrl}`)
      }
    });
}

/**
 * Encodes url value using bota() and string placing of equal `=` and forward-slash `/` to
 * work with amplify's encoding/decoding process
 *
 * @param url
 * @returns {string}
 */
function encodeUrl(url) {
  return btoa(url)
    .replace(/=/g, '_')
    .replace(/\//g, '-');
}

/**
 * Decodes encodedUrl value using string placing of equal `=` and forward-slash `/` and aotb() to
 * work with amplify's encoding/decoding process
 *
 * @param codedUrl
 * @returns {string}
 */
function decodeUrl(codedUrl) {
  const unescapedString = codedUrl
    .replace(/_/g, '=')
    .replace(/-/g, '/');

  return atob(unescapedString);
}

/**
 * Decodes encodedUrl and compares current window.location.href to url value, if different does a
 * windows.location.assign(url)
 *
 * @param encodedUrl
 */
function redirectToEncodedUrl(encodedUrl) {
  const url = decodeUrl(encodedUrl);
  const locationChanged = window.location.href !== url;

  if (locationChanged && url.startsWith(window.location.origin)) {
    window.location.assign(url);
  }
}

/**
 * setter local storage saving of csrf token
 * @param token
 */
function setCsrfToken(token){
  localStorage.setItem(CSRF_STORAGE_KEY, token);
}

/**
 * getter for local storage saving of csrf token
 * @returns {string|string}
 */
function getCsrfToken(){
  return localStorage.getItem(CSRF_STORAGE_KEY)
}

export default {
  getToken,
  encodeUrl,
  decodeUrl,
  redirectToEncodedUrl,
  getFssoRetryBlock,
  setFssoRetryBlock,
  getCsrfToken,
  setCsrfToken
};
