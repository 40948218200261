import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import {Form} from 'antd';
import {FormattedMessage} from 'react-intl';

import InfoLabel from '../InfoLabel/InfoLabel';
import messages from './messages';

import './BaseFormItem.css';

function BaseFormItem(
  {
    className,
    field,
    children,
    label,
    info,
    labelRight,
    labelBold,
    required,
    dependencies,
    labelCol,
    wrapperCol,
    clearfix,
    colon = false,
    validateFirst = false,
    rules = [],
    popoverMaxWidth,
    popoverPlacement,
    valuePropName,
    noStyle,
    extra,
    normalize
  }) {

  if (required && !_.find(rules, {required: true})) {
    rules.unshift({
      required: true,
      message: <FormattedMessage {...messages.required}/>
    });
  }

  const infoLabel = label && (
    <InfoLabel
      label={label}
      labelBold={labelBold}
      info={info}
      secondary={labelRight}
      maxWidth={popoverMaxWidth}
      placement={popoverPlacement}
      clearfix={clearfix}
    />
  );

  return (
    <Form.Item
      className={classNames('BaseFormItem', className)}
      name={field}
      rules={rules}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      colon={colon}
      label={infoLabel}
      dependencies={dependencies}
      validateFirst={validateFirst}
      valuePropName={valuePropName}
      noStyle={noStyle}
      extra={extra}
      normalize={normalize}
    >
      {children}
    </Form.Item>
  );
}

BaseFormItem.propTypes = {
  className: PropTypes.string,
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  label: PropTypes.node,
  info: PropTypes.node,
  clearfix: PropTypes.bool,
  labelCol: PropTypes.object,
  wrapperCol: PropTypes.object,
  colon: PropTypes.bool,
  labelRight: PropTypes.node,
  labelBold: PropTypes.bool,
  required: PropTypes.bool,
  rules: PropTypes.array,
  dependencies: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  validateFirst: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  popoverPlacement: PropTypes.string,
  popoverMaxWidth: PropTypes.string,
  valuePropName: PropTypes.string,
  noStyle: PropTypes.bool,
  extra: PropTypes.node,
  normalize: PropTypes.func,
};

export default BaseFormItem;
