import {useMemo} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {Card, Skeleton, Space} from 'antd';

import Titles from './Titles';

const DEFAULT_PADDING = '16px 24px';
const NONE_PADDING = '1px 0 0';
const LARGE_PADDING = '24px 24px';

// Allows for standard padding setup or to be dynamic based on padding passed
function mapBodyPaddingToStyle(padding) {
  switch (padding) {
    case 'default':
      return DEFAULT_PADDING;
    case 'none':
      return NONE_PADDING;
    case 'large':
      return LARGE_PADDING;
    default:
      return padding;
  }
}

function HorizonCard(props) {
  const {
    title,
    subtitle,
    info,
    extra,
    children,
    isLoading,
    bodyPadding = 'default',
    className,
    isNested,
    actions,
    customTitle = null
  } = props;

  const includesTitle = useMemo(() => {
    return title || subtitle || info
  }, [title, subtitle, info])

  return (
    <Card
      className={classNames('HorizonCard', className)}
      title={includesTitle ? (
        <Titles
          title={title}
          subtitle={subtitle}
          info={info}
          isLoading={isLoading}
          size={isNested ? 'small' : 'default'}
        />
      ) : customTitle}
      extra={!isLoading && extra}
      bodyStyle={{padding: mapBodyPaddingToStyle(bodyPadding)}}
      type={isNested && 'inner'}
      actions={actions}
    >
      <Skeleton loading={isLoading}>
        <Space direction={'vertical'} style={{width: '100%'}} size={16}>
          {children}
        </Space>
      </Skeleton>
    </Card>
  );
}

HorizonCard.propTypes = {
  title: PropTypes.node,
  customTitle: PropTypes.node,
  subtitle: PropTypes.node,
  info: PropTypes.node,
  extra: PropTypes.node,
  bodyPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  isNested: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.node),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default HorizonCard;