
export const MODALITY_TYPES = {
  EMAIL: 'EMAIL',
  SMS: 'SMS'
};

export const DEFAULT_SESSION_TIMEOUT_DURATION = 2;

export const NOTIFICATION_TYPES = {
  ALERT: 'ALERT_NOTIFICATION'
};

// per the doc:
// https://bitbucket.org/crisis24/wc4-api-docs/src/master/src/docs/private/preferences/wc4-preferences-ms.yaml
// we should be able to have a flat object with all the types. discuss with team
export const USER_PREF_TYPES = {
  SEARCH_FILTER: 'SEARCH_FILTER'
};

export const DESTINATION_TYPE_KEYS = {
  DOMESTIC: 'domestic',
  INTERNATIONAL: 'international',
  HOTEL_ONLY: 'alwaysSendForHotelOnlyTrips'
}