export const USER_REQUEST = 'session/USER_REQUEST';
export const USER_SUCCESS = 'session/USER_SUCCESS';
export const USER_ERROR = 'session/USER_ERROR';
export const TOKEN_RENEW = 'session/TOKEN_RENEW';
export const LOGOUT_REQUEST = 'session/LOGOUT_REQUEST';
export const AUTHENTICATION_REQUEST = 'session/AUTHENTICATION_REQUEST';
export const IDLE_TIMEOUT_REQUEST = 'session/IDLE_TIMEOUT_REQUEST';
export const IDLE_TIMEOUT_SUCCESS = 'session/IDLE_TIMEOUT_SUCCESS';
export const IDLE_TIMEOUT_FAILURE = 'session/IDLE_TIMEOUT_FAILURE';
export const SET_RENEW_INTERVAL = 'session/SET_RENEW_INTERVAL';

export const SET_CURRENT_PERSONA = 'session/SET_CURRENT_PERSONA';

export const GET_ORG_CLIENT_LOGO_REQUEST = 'session/GET_ORG_CLIENT_LOGO_REQUEST';
export const GET_ORG_CLIENT_LOGO_SUCCESS = 'session/GET_ORG_CLIENT_LOGO_SUCCESS';
export const GET_ORG_CLIENT_LOGO_FAILURE = 'session/GET_ORG_CLIENT_LOGO_FAILURE';

export const GET_ORG_CLIENT_POST_LOGIN_CONFIG_REQUEST = 'session/GET_ORG_CLIENT_POST_LOGIN_CONFIG_REQUEST';
export const GET_ORG_CLIENT_POST_LOGIN_CONFIG_SUCCESS = 'session/GET_ORG_CLIENT_POST_LOGIN_CONFIG_SUCCESS';
export const GET_ORG_CLIENT_POST_LOGIN_CONFIG_FAILURE = 'session/GET_ORG_CLIENT_POST_LOGIN_CONFIG_FAILURE';

export const SET_ONBOARDING_FIELDS = 'session/SET_ONBOARDING_FIELDS';
export const SAVE_ONBOARDING_FIELDS_REQUEST = 'session/SAVE_ONBOARDING_FIELDS_REQUEST';
export const SAVE_ONBOARDING_FIELDS_SUCCESS = 'session/SAVE_ONBOARDING_FIELDS_SUCCESS';
export const SAVE_ONBOARDING_FIELDS_FAILURE = 'session/SAVE_ONBOARDING_FIELDS_FAILURE';

export const GET_ACTIVE_CURRENT_PERMISSIONS = 'session/GET_ACTIVE_CURRENT_PERMISSIONS';

export const USER_ORG_TREE_SUCCESS = 'session/USER_ORG_TREE_SUCCESS';

export const MOBILE_HIDE_MAIN_HEADER = 'session/MOBILE_HIDE_MAIN_HEADER';
export const MFA_BY_PASS = 'session/MFA_BY_PASS';
